<template>
	<div class="classification-main">
		<div class="data-content">
			<button class="add-btn" @click="addClick">添加分类</button>
			<div class="query-content flex flex-col-center">
				<div class="input-content flex flex-col-center">
					<span class="title-text">产品分类</span>
					<el-cascader v-model="typeText" :options="proClass" collapse-tags :props="{value: 'id', label: 'name', multiple: true}"></el-cascader>
				</div>
				<div class="input-content flex flex-col-center">
					<span class="title-text">状态</span>
					<el-select v-model="statusText" placeholder="请选择">
						<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<div class="rest-btn-content flex flex-col-center">
					<button class="r-btn query-btn" @click="queryClick">搜索</button>
					<button class="r-btn res-btn" @click="resterClick">重置</button>
				</div>
			</div>
			<div class="title-content flex flex-col-center">
				<p :style="'padding-left: 49px;box-sizing: border-box;text-align: left;width:'+itemStyle[0]">分类名称</p>
				<p :style="'width:'+itemStyle[1]">产品数</p>
				<p :style="'width:'+itemStyle[2]">添加时间</p>
				<p :style="'width:'+itemStyle[3]">状态</p>
				<p :style="'width:'+itemStyle[4]">操作</p>
			</div>
			<div class="tabel-content">
				<div class="tabel-mains" v-for="(item, index) in allData" :key="index">
					<list-item :msg="item" :itemStyle="{background: '#F6F7F9'}" @deleteEvent="initData"></list-item>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import listItem from "./list-item.vue"
	export default{
		components: {
			listItem
		},
		data(){
			return{
				typeText: [],//产品分类
				proClass: [],//分类数据
				statusText: '',//状态
				statusList: [{ value: '1', label: '启用'}, { value: '2', label: '禁用'}],
				allData: [],//所有数据
				isLoading: false,//是否正在加载
				itemStyle: ['520px', '296px', '353px', '228px', '240px'],
			}
		},
		mounted() {
			this.initData();
			this.getPorClass();
		},
		methods:{
			initData() {
				this.isLoading = true;
				let data = {
					node_id: this.typeText.length>0?this.typeText.map(item => item[item.length-1]):'',
					status: this.statusText
				}
				this.$api.cateList(data).then(res=>{
					this.isLoading = false;
					this.allData = res.data;
				}).catch(err=>{
					this.isLoading = false;
				})
			},
			//产品分类
			getPorClass() {
				this.$api.cateList().then(res=>{
					this.proClass = res.data;
				})
			},
			//添加分类
			addClick() {
				this.$router.push({path: '/addClassify', query: {type:'home_add'}});
			},
			//搜索
			queryClick() {
				this.initData();
			},
			//重置
			resterClick() {
				this.typeText = [];
				this.statusText = '';

        this.queryClick()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.classification-main{padding: 18px;width:100%; height: 100%;box-sizing: border-box; 
		.data-content{background-color: #fff;padding: 30px 20px;width:100%; min-height: 100%;box-sizing: border-box;
			.add-btn{width: 110px;height: 40px;background: #04CD83;border-radius: 4px;font-size: 14px;color: #fff;text-align: center;line-height: 40px;}
			.query-content{padding: 20px;
				.input-content{margin-right: 60px;
					.title-text{font-size: 12px;color: #21333F;margin-right: 12px;}
					.el-cascader{width: 280px;background: #FFFFFF;border-radius: 4px;font-size: 12px;}
					.el-select{width: 140px;height: 34px;background: #FFFFFF;border-radius: 4px;}
				}
				.rest-btn-content{margin-left: -50px;
					.r-btn{width: 70px;height: 34px;border-radius: 4px;line-height: 34px;font-size: 12px;text-align: center;margin-right: 10px;}
					.query-btn{background: #3984F5;color: #fff;}
					.res-btn{background: #EEF1F3;color: #6C7880;}
				}
			}
			.title-content{margin: 20px 0 15px 0;background: #F6F7F9;border-radius: 2px;height: 40px;border: 1px solid #DBE1E5;
				p{font-size: 12px;color: #242D33;}
			}
			.tabel-content{
				.tabel-mains{margin-bottom: 14px;border: 1px solid #DCE5EC;}
			}
		}
	}
	::v-deep(.el-range__icon){display: none;}
	::v-deep(.el-input__inner){height: 34px;line-height: 34px;font-size: 12px;}
	::v-deep(.el-range-input){font-size: 12px;}
	// ::v-deep(.el-tree-node__content){margin-bottom: 14px;border: 1px solid #DCE5EC;}
	
</style>