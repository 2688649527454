<template>
	<div class="list-item-main">
		<div class="index-content flex flex-col-center flex-row-between" :style="[itemStyle]">
			<span style="width: 520px;box-sizing: border-box;margin-left: 27px;">
				<i class="el-icon-caret-right" :style="isShowOne?'transform: rotate(90deg);transition: all 0.3s;':'transform: rotate(0deg);transition: all 0.3s;'" @click="showItemClick"></i>
				<span>{{msg.name}}</span>
			</span>
			<span class="r-tabel flex flex-col-center">
				<span style="width: 296px;">{{msg.product_num}}</span>
				<span style="width: 353px;">{{msg.create_time}}</span>
				<span style="width: 228px;">
					<el-switch :value="getStatu(msg.status)" active-color="#3984F5" inactive-color="#CEDAE0" @change="switchChange($event, msg)"></el-switch>
				</span>
				<span style="width: 240px;">
					<el-button type="text" size="mini" @click="addBottomClass(msg, 0)">添加下级分类</el-button>
					<el-button type="text" size="mini" @click="addBottomClass(msg, 1)">编辑</el-button>
					<el-button type="text" size="mini" @click="showDelete(msg)">删除</el-button>
				</span>
			</span>
		</div>
		<div :class="[isShowOne?'show-indexs-content':'hide-indexs-content']" v-for="(item, index) in msg.children" :key="index">
			<div class="indexs-content flex flex-col-center flex-row-between">
				<span style="width: 520px;">
					<i class="el-icon-caret-right" @click="showItemsClick" :style="isShowTwo?'transform: rotate(90deg);transition: all 0.3s;':'transform: rotate(0deg);transition: all 0.3s;'"></i>
					<span>{{item.name}}</span>
				</span>
				<span class="r-tabel flex flex-col-center">
					<span style="width: 296px;">{{item.product_num}}</span>
					<span style="width: 353px;">{{item.create_time}}</span>
					<span style="width: 228px;">
						<el-switch  :value="getStatu(item.status)" active-color="#3984F5" inactive-color="#CEDAE0" @change="switchChange($event, item)"></el-switch>
					</span>
					<span style="width: 240px;">
						<el-button type="text" size="mini" @click="addBottomClass(item, 0)">添加下级分类</el-button>
						<el-button type="text" size="mini" @click="addBottomClass(item, 1)">编辑</el-button>
						<el-button type="text" size="mini" @click="showDelete(item)">删除</el-button>
					</span>
				</span>
			</div>
			<div :class="['items-content flex flex-col-center flex-row-between',isShowTwo?'show-indexs-content':'hide-indexs-content']" v-for="(items, indexs) in item.children" :key="items.id">
				<span style="width: 520px;">{{items.name}}</span>
				<span class="r-tabel flex flex-col-center">
					<span style="width: 296px;">{{items.product_num}}</span>
					<span style="width: 353px;">{{items.create_time}}</span>
					<span style="width: 228px;">
						<el-switch :value="getStatu(items.status)" active-color="#3984F5" inactive-color="#CEDAE0" @change="switchChange($event, items)"></el-switch>
					</span>
					<span style="width: 240px;">
						<el-button type="text" size="mini" @click="addBottomClass(items, 1)">编辑</el-button>
						<el-button type="text" size="mini" @click="showDelete(items)">删除</el-button>
					</span>
				</span>
			</div>
		</div>
		<tip-popup :show="deleteData.show" @enterEvent="deleteClick" @closeEvent="deleteData.show=false"></tip-popup>
	</div>
</template>

<script>
	import tipPopup from "../components/tip-popup.vue"
	export default{
		name: 'list-item',
		props: {
			msg: {
				type: Object,
				default: {}
			},
			itemStyle: {
				type: Object,
				default: {}
			},
			chilIndex: {
				type: [Number, String],
				default: 0
			}
		},
		components: {
			tipPopup
		},
		data(){
			return{
				isShowOne: false,
				isShowTwo: false,
				deleteData: {show: false, data: ''}//显示提示弹窗
			}
		},
		methods:{
			//添加下级分类
			addBottomClass(e, types) {
				let type = types==0?'list_add':'edit';
				this.$router.push({path: '/addClassify', query: {type: type, id: e.id}});
			},
			//删除
			deleteClick() {
				this.$api.productCateDel(this.deleteData.data.id).then(res=>{
					this.$message({type: 'success', message: '删除成功！'});
					this.$emit('deleteEvent');
				})
			},
			//开关
			switchChange(e, item) {
				this.$api.cateStatus({id: item.id, status: e?1:2}).then(res=>{
					this.$message({type: 'success', message: '修改成功！'});
					this.$emit('deleteEvent');
				})
			},
			//展开
			showItemClick(e) {
				this.isShowOne = !this.isShowOne;
				this.isShowTwo = false;
			},
			showItemsClick(e) {
				this.isShowTwo = !this.isShowTwo;
			},
			//显示删除提示
			showDelete(e) {
				this.deleteData.data = e;
				// this.deleteData.show = true;
        this.$confirm('是否确认删除当前分类？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteClick();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
			},
			//获取状态
			getStatu(num) {
				if (num==1) return true;
				if (num==2) return false;
				return true;
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.list-item-main{
		.index-content{height: 48px;line-height: 48px;
				span{font-size: 12px;color: #242D33;}
				i{margin: 0 10px 0 0;
					&:hover{cursor: pointer;}
				}
			}
		}
		.indexs-content{margin-left: 60px;height: 48px;line-height: 48px;border-bottom: 1px solid #DCE5EC;
			i{margin: 0 10px 0 0;
				&:hover{cursor: pointer;}
			}
		}
		.items-content{margin-left: 104px;height: 48px;line-height: 48px;border-bottom: 1px solid #DCE5EC;
			
		}
		.show-indexs-content{height: auto; transition: all 0.3s;}
		.hide-indexs-content{height: 0;overflow: hidden;transition: all 0.3s;border: none;}
	
	
</style>