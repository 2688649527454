<template>
	<div class="tip-main">
		<el-dialog
		  :title="title"
		  :visible.sync="show"
		  width="400px"
		  :before-close="handleClose">
		  <p>{{content}}</p>
		  <span slot="footer" class="flex flex-col-center flex-row-center">
		    <el-button type="primary" @click="enterClick">确 定</el-button>
		    <el-button @click="close">取 消</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'tip-popup',
		props: {
			show: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: '提示'
			},
			content: {
				type: String,
				default: '是否确认删除'
			}
		},
		data(){
			return{
				
			}
		},
		methods:{
			enterClick() {
				this.$emit('enterEvent');
			},
			close() {
				this.$emit('closeEvent');
			},
			handleClose(done) {
				done();
				this.$emit('closeEvent');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tip-main{
		p{text-align: center;font-size: 14px;color: #242D33;}
	}
	
</style>